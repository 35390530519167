body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.node-selected {
  box-shadow: 0 0 0 2px darkred;
}